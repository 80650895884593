import { Autocomplete, Button, DateTimePicker, IColumn, LogoLoadingIndicator } from '@Wonder-Cave/ui';
import { FilterDataTypeEnum, GREnvironment, IClientEstimate, IEstimateRequest } from '@shared/models';
import moment from 'moment';
import { useEffect, useState } from 'react';
import "react-datetime/css/react-datetime.css";
import { axiosPost } from '../../authAxios';
import useClientsManual from '../../hooks/useClientsManual';
import useTenants from '../../hooks/useTenants';
import { IDropdownValue } from '../shared/Form/Dropdown';
import { generateRowForItem } from '../shared/Table/Table';

interface ITableCard {
  columns: IColumn[];
  items: any;
  defaultOpen?: boolean;
  dark?: boolean;
}

const TableCard = ({ columns, items, defaultOpen, dark }: ITableCard) => {
  return <table className='table-card'>
    <thead>
      <tr>
        {columns.map((column, index) => {
          return <th className={`${dark ? 'bg-dark-gray text-white' : 'bg-light-gray'} ${index === 0 ? 'rounded-tl-2.5xl' : ''} ${index === columns.length - 1 ? 'rounded-tr-2.5xl' : ''}`}>
            <td className='px-8 py-4 font-semibold'>
              {index === 0 ? <h3>{column.headerName}</h3> : <h5>{column.headerName}</h5>}
            </td>
          </th>;
        })}
      </tr>
    </thead>
    <tbody className='table-card-body'>
      {items.map((item, index) => <tr className='table-card-row'>{generateRowForItem(item, index, columns)}</tr>)}
    </tbody>
  </table>;
};

const getColumns = (client: string) => ([
  { headerName: client, fieldName: 'type', fieldType: FilterDataTypeEnum.STRING },
  { headerName: 'BW10DLC', fieldName: 'bandwidth', fieldType: FilterDataTypeEnum.NUMBER },
  { headerName: 'TELNYX10DLC', fieldName: 'telnyx', fieldType: FilterDataTypeEnum.NUMBER },
  { headerName: 'BWTF', fieldName: 'bandwidthTf', fieldType: FilterDataTypeEnum.NUMBER },
  { headerName: 'TELNYXTF', fieldName: 'telnyxTf', fieldType: FilterDataTypeEnum.NUMBER },
  { headerName: 'SINCH10DLC', fieldName: 'sinch', fieldType: FilterDataTypeEnum.NUMBER }
]);

const MPSCalculator = () => {
  const [time, setTime] = useState<Date>(moment(new Date()).add(15, 'm').toDate());
  const [error, setError] = useState<string>();
  const [environment, setEnvironment] = useState<IDropdownValue>();
  const [clients, setClients] = useState<IDropdownValue[]>();
  const [search, setSearch] = useState<string>();
  const [calculations, setCalculations] = useState<IClientEstimate[]>([]);
  const [loading, setLoading] = useState(false);
  const [{ data: tenantsData, loading: tenantsLoading, error: tenantsError }, refreshTenants] = useTenants();
  const [{ data: clientData, loading: clientsLoading, error: clientsError }, refreshClients] = useClientsManual({ environment: environment?.value, name: search });
  const environments = new Map();
  tenantsData?.forEach(td => { environments.set(td.environment.id, { environment: td.environment.name, tenant: td.id }); });
  useEffect(() => {
    if (environment?.value && search) {
      refreshClients({
        params: {
          environment: environment?.value,
          name: search
        }
      });
    }
  }, [environment, search]);

  const calculateMPS = async () => {
    // Check valid time
    if (time < moment(new Date()).add(15, 'm').toDate()) {
      setError('Time must be at least 15 minutes in the future');
    } else {
      try {
        setLoading(true);
        const response = await axiosPost('/mps-calculator/estimates', {
          startsAt: time.toISOString(),
          tenantId: environment?.additionalData,
          clientIds: clients?.map(c => c.value)
        } as IEstimateRequest);
        if (response.status === 200) {
          console.log(response);
          setCalculations(response.data?.estimates);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };
  let clientOptions;
  if (environment?.value === GREnvironment.SHARED) {
    clientOptions = clientData?.records?.map(cd => ({ label: `${cd.name} - ${tenantsData?.find(td => td.id === cd.tenantId)?.name}`, value: cd.id }));
  } else {
    clientOptions = clientData?.records?.map(cd => ({ label: cd.name, value: cd.id }));
  }

  const providerSum = calculations.reduce((providerSum, calculation) => {
    providerSum.sms.bandwidth += calculation.sms.bandwidth;
    providerSum.sms.bandwidthTf += calculation.sms.bandwidthTf;
    providerSum.sms.telnyx += calculation.sms.telnyx;
    providerSum.sms.telnyxTf += calculation.sms.telnyxTf;
    providerSum.sms.sinch += calculation.sms.sinch;
    providerSum.mms.bandwidth += calculation.mms.bandwidth;
    providerSum.mms.bandwidthTf += calculation.mms.bandwidthTf;
    providerSum.mms.telnyx += calculation.mms.telnyx;
    providerSum.mms.telnyxTf += calculation.mms.telnyxTf;
    providerSum.mms.sinch += calculation.mms.sinch;
    return providerSum;
  }, { mms: { bandwidth: 0, bandwidthTf: 0, telnyx: 0, telnyxTf: 0, sinch: 0 }, sms: { bandwidth: 0, bandwidthTf: 0, telnyx: 0, telnyxTf: 0, sinch: 0 } });

  return <div className='px-24 pb-16'>
    <h1 className='mb-2'>MPS Calculator</h1>
    <div className='flex items-center justify-between'>
      <div className='flex items-center justify-between w-full mt-8 mr-24'>
        <div className='basis-1/3'>
          <DateTimePicker label='Time' full leadingLabel onlyTime value={time} onChange={(date) => {
            setTime(date);
            time < moment(new Date()).add(15, 'm').toDate() ? setError('Time must be at least 15 minutes in the future') : setError('');
          }} error={error} />
        </div>
        <div className='basis-1/3'><Autocomplete label='Environment' loading={tenantsLoading} leadingLabel value={environment} onChange={(e) => { setEnvironment(e); setClients([]); }} options={Array.from(environments.keys()).map(e => ({ label: environments.get(e).environment, value: environments.get(e).environment, additionalData: environments.get(e).tenant }))} /></div>
        <div className='basis-1/4 max-w-[25%]'><Autocomplete disabled={!environment} loading={clientsLoading} multiple label='Clients' refreshOptions={async (search) => await setSearch(search)} leadingLabel value={clients ?? []} onChange={(e) => setClients(e)} options={clientOptions} /></div>
      </div>
      <Button disabled={!!error || !environment || (clients?.length ?? 0) <= 0} isLoading={loading} onClick={calculateMPS}>Calculate</Button>
    </div>
    {(calculations.length > 0 && !loading) ? <div className='mt-8 space-y-8'>
      <div className='flex px-14'>
        <h5 className='basis-1/8'>CLIENT</h5>
        <h5 className='mx-auto'>TOTAL REMAINING CAPACITY</h5>
      </div>
      {calculations.map(c => {
        const items = [{ type: 'SMS', ...c.sms }, { type: 'MMS', ...c.mms }];
        return <TableCard columns={getColumns(clients?.find(cl => cl.value === c.client)?.label ?? '')} items={items} />;
      })}
      {calculations.length > 1 && <TableCard columns={getColumns('Total MPS')} items={[{ type: 'SMS', ...providerSum.sms }, { type: 'MMS', ...providerSum.mms }]} />}
    </div> : loading ? <div className='flex justify-center mt-24'><LogoLoadingIndicator /></div> : <></>}
  </div>;
};

export default MPSCalculator;